var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import Vue from "vue";
import { mapState } from "vuex";
import gql from "graphql-tag";
import { createEmptyForwardRelayConnection } from "../../utils/apollo";
import flatMap from "lodash/flatMap";
export default Vue.extend({
    data: function () {
        return {};
    },
    computed: __assign({ generalItems: function () {
            return __spreadArray(__spreadArray(__spreadArray(__spreadArray([
                {
                    location: "",
                    changeFrequency: "daily",
                },
                {
                    location: "about",
                    changeFrequency: "weekly",
                },
                {
                    location: "agents",
                    changeFrequency: "weekly",
                },
                {
                    location: "contact",
                    changeFrequency: "weekly",
                },
                {
                    location: "faqs",
                    changeFrequency: "weekly",
                }
            ], (this.faqCategories || []).map(function (_a) {
                var slug = _a.slug;
                return ({
                    location: "faqs/".concat(slug),
                    changeFrequency: "weekly",
                });
            }), true), [
                {
                    location: "terms-and-conditions",
                    changeFrequency: "weekly",
                },
                {
                    location: "terms-and-conditions/mobile-and-data",
                    changeFrequency: "weekly",
                },
                {
                    location: "terms-and-conditions/health-cover",
                    changeFrequency: "weekly",
                },
                {
                    location: "terms-and-conditions/accommodation",
                    changeFrequency: "weekly",
                },
                {
                    location: "terms-and-conditions/agents",
                    changeFrequency: "weekly",
                },
                {
                    location: "blog",
                    changeFrequency: "daily",
                }
            ], false), (this.liveBlogPosts || createEmptyForwardRelayConnection()).edges.map(function (_a) {
                var slug = _a.node.slug;
                return ({
                    location: "blog/".concat(slug),
                    changeFrequency: "weekly",
                });
            }), true), [
                {
                    location: "sign-in",
                    changeFrequency: "monthly",
                },
            ], false);
        }, accommodationItems: function () {
            return __spreadArray(__spreadArray(__spreadArray([
                {
                    location: "accommodation",
                    changeFrequency: "daily",
                }
            ], (this.citiesWithListings || []).map(function (_a) {
                var slug = _a.slug;
                return ({
                    location: "accommodation/".concat(slug),
                    changeFrequency: "hourly",
                });
            }), true), (this.institutions || []).map(function (_a) {
                var slug = _a.slug, citySlug = _a.citySlug;
                return ({
                    location: "accommodation/".concat(citySlug, "/").concat(slug),
                    changeFrequency: "hourly",
                });
            }), true), flatMap((this.publishedPropertySlugs || []).map(function (slug) { return [
                {
                    location: "accommodation/listing/".concat(slug),
                    changeFrequency: "weekly",
                },
                // would need to generate with lease terms, so would be too heavy
                // {
                //   location: `accommodation/listing/${slug}/enquire-now`,
                //   changeFrequency: "weekly",
                // },
            ]; })), true);
        }, mobileItems: function () {
            return __spreadArray(__spreadArray([
                {
                    location: "mobile-and-data",
                    changeFrequency: "weekly",
                }
            ], (this.mobileDataProducts || []).map(function (_a) {
                var slug = _a.slug;
                return ({
                    location: "mobile-and-data/".concat(slug),
                    changeFrequency: "weekly",
                });
            }), true), (this.mobilePhoneProducts || []).map(function (_a) {
                var slug = _a.slug;
                return ({
                    location: "mobile-and-data/".concat(slug),
                    changeFrequency: "weekly",
                });
            }), true);
        }, healthCoverItems: function () {
            return [
                {
                    location: "health-cover",
                    changeFrequency: "weekly",
                },
                {
                    location: "health-cover/details",
                    changeFrequency: "weekly",
                },
            ];
        }, bankingItems: function () {
            return [
                {
                    location: "banking",
                    changeFrequency: "weekly",
                },
            ];
        }, items: function () {
            var _this = this;
            return __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], this.generalItems, true), this.accommodationItems, true), this.healthCoverItems, true), this.mobileItems, true), this.bankingItems, true).map(function (_a) {
                var location = _a.location, item = __rest(_a, ["location"]);
                return (__assign({ location: "".concat(_this.baseUrl, "/").concat(_this.locale, "/").concat(_this.currency.toLowerCase(), "/").concat(location) }, item));
            });
        } }, mapState(["baseUrl", "locale", "currency"])),
    apollo: {
        faqCategories: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      {\n        faqCategories {\n          slug\n        }\n      }\n    "], ["\n      {\n        faqCategories {\n          slug\n        }\n      }\n    "]))),
        mobilePhoneProducts: gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      {\n        mobilePhoneProducts {\n          slug\n        }\n      }\n    "], ["\n      {\n        mobilePhoneProducts {\n          slug\n        }\n      }\n    "]))),
        mobileDataProducts: gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      {\n        mobileDataProducts {\n          slug\n        }\n      }\n    "], ["\n      {\n        mobileDataProducts {\n          slug\n        }\n      }\n    "]))),
        institutions: gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      {\n        institutions {\n          slug\n          citySlug\n        }\n      }\n    "], ["\n      {\n        institutions {\n          slug\n          citySlug\n        }\n      }\n    "]))),
        citiesWithListings: gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      {\n        citiesWithListings {\n          slug\n        }\n      }\n    "], ["\n      {\n        citiesWithListings {\n          slug\n        }\n      }\n    "]))),
        publishedPropertySlugs: gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      {\n        publishedPropertySlugs\n      }\n    "], ["\n      {\n        publishedPropertySlugs\n      }\n    "]))),
        liveBlogPosts: gql(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n      {\n        liveBlogPosts(first: 1000) {\n          edges {\n            node {\n              slug\n            }\n          }\n        }\n      }\n    "], ["\n      {\n        liveBlogPosts(first: 1000) {\n          edges {\n            node {\n              slug\n            }\n          }\n        }\n      }\n    "]))),
    },
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
