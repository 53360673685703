var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var appendConnection = function (previous, next) { return (__assign(__assign({}, previous), { edges: __spreadArray(__spreadArray([], previous.edges, true), next.edges, true), pageInfo: next.pageInfo })); };
export var createEmptyForwardRelayConnection = function () { return ({
    edges: [],
    pageInfo: {
        hasNextPage: false,
    },
}); };
export function createRelayConnectionFetchMoreQueryUpdater(connectionName) {
    return function (previousResult, _a) {
        var _b;
        var fetchMoreResult = _a.fetchMoreResult;
        return __assign(__assign({}, previousResult), (_b = {}, _b[connectionName] = appendConnection(previousResult[connectionName], fetchMoreResult
            ? fetchMoreResult[connectionName]
            : createEmptyForwardRelayConnection()), _b));
    };
}
